/* You can add global styles to this file, and also import other style files */
list-players-component {
    font-size: 0.8em;
}

rosters-component {
    font-size: 0.8em;
}

.pagination {
    --bs-pagination-padding-x: .5rem;
    --bs-pagination-padding-y: .25rem;
    --bs-pagination-font-size: .875rem;
    --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.heavyTopBorder > td {
    border-top-style: solid;
    border-top-width: 2px;
    border-top-color: rgb(13, 110, 253);
}

.lato-thin {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.lato-light {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.lato-regular {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lato-bold {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.lato-black {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.lato-thin-italic {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.lato-light-italic {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.lato-regular-italic {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.lato-bold-italic {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.lato-black-italic {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: italic;
}

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}